<template>
  <v-container>
    <v-card class="table-card">
      <v-card-title>
        <v-row class="d-flex justify-space-between align-center mb-2 px-2">
          <div v-if="!device" class="d-flex">
            <v-avatar size="100" class="elevation-6 ms-1" :color="color">
              <v-icon dark size="64">
                mdi-account-group
              </v-icon>
            </v-avatar>
            <div class="table-card-title ms-5 text-h2">
              {{ $phraser.set('all users') }}
            </div>
          </div>
          <v-text-field
            v-model="search"
            class="d-flex table-search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
          />
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
        :sort-by="['created_at']"
        multi-sort
        :class="clickable ? 'clickable' : ''"
        @click:row="handleClick"
      >
        <!-- :custom-filter="searchUsers" -->
        <template v-slot:item.name="{ item }">
          {{ `${item.first_name} ${item.last_name}` }}
        </template>
        <!-- <template v-slot:item.client="{ item }">
          {{ clientsNames[item.client] }}
        </template> -->
        <template v-slot:item.updated_at="{ item }">
          {{ formatTime(item.updated_at) }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ formatTime(item.created_at) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import moment from 'moment'
export default {
  name: 'Users',
  crumb: 'משתמשים',

  components: {},

  props: {
    device: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    icon: 'md-account-tie',
    color: 'indigo',
    clickable: true,
    // loading: false,
    search: '',
    headers: [],
  }),

  computed: {
    loading () {
      return this.$store.getters['users/loading']
    },
    users () {
      if (this.device) {
        return this.$store.getters['users/usersByDevice'](this.device).users
      } else {
        const users = this.$store.getters['users/users']
        return [...users].map(user => {
          if (user.client) {
            user.client_name = this.clientsNames[user.client]
          }
          return user
        })
      }
    },
    clientsNames () {
      return this.$store.getters['clients/clientsNames']
    },
  },

  // watch: {
  //   users () {
  //     this.loading = false
  //   },
  // },

  created () {
    moment.locale(this.$i18n.locale)
  },
  mounted () {
    this.setHeaders()
  },

  methods: {
    // getUsers () {
    //   this.$store.dispatch('users/get')
    // },
    handleClick (user) {
      if (this.clickable) {
        this.$router.push({ name: 'User', params: { slug: user.slug } })
      }
    },
    searchUsers (value, search, item) {
      // if (item.client) {
      //   console.log(item.client)
      // }
      const searchIn = [
        item.first_name,
        item.last_name,
        item.email,
        item.client,
      ]
      return searchIn.findIndex(v => v.includes(search)) >= 0
    },
    setHeaders () {
      this.headers = [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: this.$t('email'), value: 'email', sortable: false },
        { text: this.$t('client'), value: 'client_name' },
        { text: this.$phraser.set('phone'), value: 'phone', sortable: false },
        { text: this.$phraser.set('updated at'), value: 'updated_at' },
        { text: this.$phraser.set('created at'), value: 'created_at' },
      ]
    },
    formatTime (time) {
      return moment(time)._isValid ? moment(time).format('lll') : time
    },
  },
}
</script>
